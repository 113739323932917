import { CreateReviewPayload, RemoveFavoriteStation } from '../../store/stations'
import { api } from '../setup'
import * as CONSTANTS from './constants'
import { IStationsApi, GetStationsParams } from './types'
import { Station } from '../../models'

export const stationsApi: IStationsApi = {
  getStationPrice: async (stationId) => {
    const { data } = await api.get<{ price: number }>(CONSTANTS.GET_STATION_PRICE(stationId))

    return data.price
  },
  getStations: async (params: GetStationsParams) => {
    const { data } = await api.get(CONSTANTS.GET_STATIONS, { params })

    return data
  },
  getFavoriteStations: async (params: GetStationsParams) => {
    const { data } = await api.get(CONSTANTS.GET_FAVORITE_STATIONS, {
      params,
    })

    return data
  },
  getStation: async (id: string) => {
    const { data } = await api.get(CONSTANTS.GET_STATION(id))
    if (!Object.keys(data).length) {
      throw new Error('Empty Result')
    }

    return data
  },
  createReview: async (payload: CreateReviewPayload) => {
    const { data } = await api.post(CONSTANTS.CREATE_REVIEW, payload)

    return data
  },
  addFavoriteStation: async (payload: RemoveFavoriteStation) => {
    const { data } = await api.post<Station>(CONSTANTS.ADD_FAVORITE_STATION, payload)
    return data
  },
  removeFavoriteStation: async (payload: RemoveFavoriteStation) => {
    const { data } = await api.post(CONSTANTS.REMOVE_FAVORITE_STATION, payload)

    return data
  },
  getLastVisitedStations: async (count = 6) => {
    const { data } = await api.get(CONSTANTS.GET_LAST_VISITED_STATIONS, { params: { count } })

    return data
  },
  getStationImagesId: async (stationId: string) => {
    const { data } = await api.get<string[]>(CONSTANTS.GET_STATIONS_IMAGE_ID(stationId))

    return data
  },
  getConnectors: async () => {
    const { data } = await api.get(CONSTANTS.GET_CONNECTORS)

    return data
  },
  getUserStations: async () => {
    const { data } = await api.get(CONSTANTS.GET_USER_STATIONS)
    return data
  },
  getStationIdFromQR: async (chargerId) => {
    const { data } = await api.get(CONSTANTS.GET_STATIONID_FROM_QR(chargerId))

    return data
  },
}
