import { useCallback, useState } from 'react'
import { ActivityIndicator, FlatList, Pressable, TouchableOpacity } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { Box, EmptyPlaceholder, Paper, Spacer, Typography } from '../../components'
import { RouterNames } from '../../../core/constants'
import { paymentSelectorHooks } from '../../../core/store/payment'
import {
  ITransaction,
  transactionsActionHooks,
  transactionsSelectorHooks,
} from '../../../core/store/transactions'
import { Icon } from '../../components/icon'
import { Transaction } from '../../components/transaction'
import { useStyles } from './styles'
import { Rfids } from './rfids'
import { Cards } from './cards'

interface Button {
  title: string
  onPress: () => void
}

export const WalletScreen = ({ navigation }: ScreenProps<RouterNames.wallet>) => {
  const { navigate } = navigation

  const { t } = useTranslation()

  const styles = useStyles()

  const getTransactions = transactionsActionHooks.getRecentTransactions()
  const transactions = transactionsSelectorHooks.getRecentTransactions()

  const isLoading = paymentSelectorHooks.getIsLoading()
  const { cards, rfids } = paymentSelectorHooks.getPaymentMethods()

  const [areTransactionsLoading, setAreTransactionsLoading] = useState(false)

  useFocusEffect(
    useCallback(() => {
      setAreTransactionsLoading(true)
      getTransactions({ callback: () => setAreTransactionsLoading(false) })
    }, [])
  )

  const buttons: Button[] = [
    { title: t('card:common.add'), onPress: () => navigate(RouterNames.addCard) },
    { title: t('card:rfid.add'), onPress: () => navigate(RouterNames.rfid, { type: 'add' }) },
  ]

  const renderButton = ({ title, onPress }: Button, index: number) => {
    return (
      <TouchableOpacity key={index} onPress={onPress} style={styles.button}>
        <Paper>
          <Box justifyContent={'flex-start'} px={'s'} gap={'m'}>
            <Icon family={'Feather'} name={'credit-card'} size={24} />
            <Typography bold text={title} />
          </Box>
        </Paper>
      </TouchableOpacity>
    )
  }

  const renderCard = (item: CardModel) => {
    return <Card card={item} showErrorMessage showIsDefault />
  }

  const onCardPress = (card: CardModel) => {
    navigate(RouterNames.cardInfo, { card })
  }

  const header = (
    <>
      <Cards data={cards} loading={isLoading} />
      <Rfids data={rfids} loading={isLoading} />
      <Box gap={'m'} my={'s'} justifyContent={'space-between'}>
        {buttons.map(renderButton)}
      </Box>
      <Box justifyContent={'space-between'} py={'m'}>
        <Typography text={t('transaction:recentTransactions')} bold size={'m'} />
        <Pressable onPress={() => navigate(RouterNames.transactionHistory)}>
          <Typography text={t('transaction:viewAll')} size={14} color={'secondaryText'} />
        </Pressable>
      </Box>
    </>
  )

  const placeholder = () => {
    if (areTransactionsLoading) {
      return (
        <>
          <Spacer vertical={'l'} />
          <ActivityIndicator size={'large'} />
        </>
      )
    }
    return <EmptyPlaceholder text={t('empty:sessions')} icon={'hexagon'} />
  }

  const separator = () => {
    return <Spacer vertical={'s'} />
  }

  const renderItem = ({ item, index }: { item: ITransaction; index: number }) => {
    return (
      <Transaction
        transaction={item}
        first={index === 0}
        last={index === transactions.length - 1}
      />
    )
  }

  return (
    <FlatList
      data={transactions}
      ListHeaderComponent={header}
      ItemSeparatorComponent={separator}
      ListEmptyComponent={placeholder}
      renderItem={renderItem}
      style={styles.container}
      contentContainerStyle={styles.content}
    />
  )
}
